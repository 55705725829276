<template>
	<div class="main-content">
		<filter-modal :filter="filter" :show="is.showing.filterModal" @on-filter="onFilter" @close="closeFilterModal" />
		<ktv-dialog
			v-model="is.showing.deleteDialog"
			:text="$t('Do you want to delete this data?', 'Do you want to delete this data?')"
			:title="$t('Delete Data', 'Delete Data')"
		>
			<template #footer>
				<ktv-button :text="$('Delete', 'Delete')" :loading="is.loading" @click="deleteData()" />
				<ktv-button :text="$('Cancel', 'Cancel')" color="light" :loading="is.loading" class="ml-2" @click="closeDeleteDialog()" />
			</template>
		</ktv-dialog>
		<ktv-breadcrumb :title="$t('Institution List', 'Institution List')" :page="$t('Institution', 'Institution')" :folder="$t('Loan', 'Loan')" />
		<ktv-table
			:columns="columns"
			:filter="false"
			:is-loading="is.loading"
			:line-numbers="false"
			:rows="institutionList"
			:search-enabled="false"
			:total-rows="totalRows"
			min-height="250px"
			mode="remote"
			:search-placeholder="$t('Search by institution name', 'Search by institution name')"
			@on-page-change="onPageChange"
			@on-per-page-change="onPerPageChange"
			@on-search="onSearch"
			@on-sort-change="onSortChange"
		>
			<template #actionleft>
				<router-link v-if="isAllowedToAdd" :to="{ name: 'Loan Institution Add' }">
					<ktv-button :text="$t('Add', 'Add')" icon="add" />
				</router-link>
			</template>
			<template #actionright>
				<ktv-button class="ml-2" :text="$t('Filter', 'Filter')" icon="filter" color="light" @click="showFilterModal" />
			</template>
			<template #columns="{ props }">
				{{ $t(props.column.label, props.column.label) }}
			</template>
			<template #rows="{ props }">
				<span v-if="props.column.field === 'button'">
					<b-dropdown size="xs" variant="link" toggle-class="text-decoration-none p-0" no-caret>
						<template #button-content>
							<img :src="icons.dropdown" />
						</template>
						<b-dropdown-item v-if="isAllowedToViewDetail" @click="showDetail(props.row)">
							<img :src="icons.view" class="imgicon" />{{ $t("View", "View") }}
						</b-dropdown-item>
						<b-dropdown-item v-if="isAllowedToUpdate" @click="showUpdate(props.row)">
							<img :src="icons.update" class="imgicon" />{{ $t("Update", "Update") }}
						</b-dropdown-item>
						<b-dropdown-item v-if="isAllowedToDelete" @click="showDeleteDialog(props.row)">
							<img :src="icons.delete" class="imgicon" />{{ $t("Delete", "Delete") }}
						</b-dropdown-item>
					</b-dropdown>
				</span>
			</template>
		</ktv-table>
	</div>
</template>
<script>
	import { each, findWhere } from "underscore"
	import { KtvButton, KtvDialog, KtvTable } from "@/components"
	import { mapActions, mapGetters, mapState } from "vuex"
	import FilterModal from "./_Filter"

	import deleteIcon from "@/assets/images/icon/icondelete.png"
	import dropdownIcon from "@/assets/images/icon/icondropdown.png"
	import updateIcon from "@/assets/images/icon/iconedit.png"
	import viewIcon from "@/assets/images/icon/iconview.png"

	const icons = {
		delete: deleteIcon,
		dropdown: dropdownIcon,
		update: updateIcon,
		view: viewIcon,
	}

	export default {
		name: "LoanInstitutionList",
		metaInfo: {
			title: "Institution List",
		},
		components: { FilterModal, KtvButton, KtvDialog, KtvTable },
		data() {
			return {
				columns: [
					{
						field: "button",
						html: true,
						sortable: false,
						tdClass: "text-center",
						thClass: "text-center",
						width: "50px",
					},
					{
						field: "loanInstitutionId",
						label: "ID",
						thClass: "text-left",
					},
					{
						field: "companyName",
						label: "Institution Name",
						thClass: "text-left",
					},
					{
						field: "legalStatus",
						label: "Legal Status",
						thClass: "text-left",
					},
					{
						field: "email",
						label: "Email",
						thClass: "text-left",
					},
					{
						field: "estYear",
						label: "Established Year",
						thClass: "text-left",
					},
				],
				deleteInstitutionId: null,
				filter: {
					establishedYear: null,
					legalStatus: null,
				},
				icons: icons,
				is: {
					loading: false,
					showing: {
						deleteDialog: false,
						filterModal: false,
					},
				},
				params: {
					limit: 10,
					order: null,
					page: 1,
					search: null,
				},
			}
		},
		computed: {
			...mapGetters({
				loanInstitutionActions: "AUTHDATA/loanInstitutionActions",
				userEntityOrganizationID: "AUTHDATA/userEntityOrganizationID",
			}),
			...mapState("INSTITUTION", ["institutionList", "totalRows"]),
			isAllowedToAdd() {
				return this.getActionPermission("add")
			},
			isAllowedToDelete() {
				return this.getActionPermission("delete")
			},
			isAllowedToUpdate() {
				return this.getActionPermission("update")
			},
			isAllowedToViewDetail() {
				return this.getActionPermission("detail")
			},
		},
		created() {
			this.getData()
		},
		beforeDestroy() {
			this.filter = {
				establishedYear: null,
				legalStatus: null,
			}

			this.params = {
				limit: 10,
				order: null,
				page: 1,
				search: null,
			}
		},
		methods: {
			...mapActions({
				deleteInstitution: "INSTITUTION/deleteInstitution",
				getInstitutionList: "INSTITUTION/getInstitutionList",
			}),
			closeDeleteDialog() {
				this.deleteInstitutionId = null

				this.is.showing = {
					...this.is.showing,
					deleteDialog: false,
				}
			},
			closeFilterModal() {
				this.is.showing = {
					...this.is.showing,
					filterModal: false,
				}
			},
			deleteData() {
				this.is = {
					...this.is,
					loading: true,
				}

				this.deleteInstitution(this.deleteInstitutionId).then(() => {
					this.closeDeleteDialog()
				})
			},
			getAction() {
				if (this.getActionPermission("view_list_all_loan_institution")) {
					return "view_list_all_loan_institution"
				}
				if (this.getActionPermission("view_list_loan_institution")) {
					return "view_list_loan_institution"
				}

				return null
			},
			getActionPermission(name = null) {
				return findWhere(this.loanInstitutionActions, { function: name })
			},
			getData() {
				this.is = {
					...this.is,
					loading: true,
				}

				this.getInstitutionList({
					...this.params,
					action: this.getAction(),
					entityId: this.userEntityOrganizationID,
				}).then(() => {
					this.is = {
						...this.is,
						loading: false,
					}
				})
			},
			onFilter(filter) {
				this.filter = {
					...this.filter,
					...filter,
				}
				let searchParam = ""

				each(this.filter, (param, paramKey) => {
					if (param) {
						if (searchParam === "") {
							searchParam += `${paramKey}=${param}`
						} else {
							searchParam += `&${paramKey}=${param}`
						}
					}
				})

				this.updateParams({
					search: searchParam,
				})

				this.getData()
			},
			onPageChange({ currentPage }) {
				this.updateParams({ page: currentPage })

				this.getData()
			},
			onPerPageChange({ currentPerPage }) {
				this.updateParams({ limit: currentPerPage, page: 1 })

				this.getData()
			},
			onSearch(searchTerm) {
				this.updateParams({ search: `companyName=${searchTerm}` })

				this.getData()
			},
			onSortChange($event) {
				const { field, type } = $event[0]
				let orderQuery = null

				if (type !== "none") {
					orderQuery = "namaField=" + field + "&orderBy=" + type.toUpperCase()
				} else {
					orderQuery = null
				}

				this.updateParams({ order: orderQuery })

				this.getData()
			},
			showDeleteDialog({ loanInstitutionId }) {
				this.deleteInstitutionId = loanInstitutionId

				this.is.showing = {
					...this.is.showing,
					deleteDialog: true,
				}
			},
			showDetail({ loanInstitutionId }) {
				this.$router.push({ name: "Loan Institution Data", params: { id: loanInstitutionId } })
			},
			showFilterModal() {
				this.is.showing = {
					...this.is.showing,
					filterModal: true,
				}
			},
			showUpdate({ loanInstitutionId }) {
				this.$router.push({ name: "Loan Institution Update", params: { id: loanInstitutionId } })
			},
			updateParams(params) {
				this.params = { ...this.params, ...params }
			},
		},
	}
</script>
