<template>
	<div class="main-content">
		<ktv-modal v-model="is.showingModal" no-close-on-backdrop no-close-on-esc :text="$t('Filter', 'Filter')" @close="close()">
			<template #content>
				<validation-observer ref="form_filter_institution" tag="form" @submit.prevent="save">
					<ktv-select v-model="localFilter.legalStatus" :options="legalStatusOptions" :label="$t('Legal Status', 'Legal Status')" />
					<ktv-select v-model="localFilter.establishedYear" :options="establishedYearOptions" :label="$t('Established Year', 'Established Year')" />
				</validation-observer>
			</template>
			<template #footer>
				<div align="right">
					<ktv-button :text="$t('Reset', 'Reset')" color="light" :loading="is.loading" icon="reset" @click="reset()" />
					<ktv-button :text="$t('Save', 'Save')" :disabled="is.loading" :loading="is.loading" class="ml-2" icon="save" @click="save()" />
				</div>
			</template>
		</ktv-modal>
	</div>
</template>
<script>
	import { KtvButton, KtvModal, KtvSelect } from "@/components"
	import { map, range } from "underscore"
	import { mapActions, mapState } from "vuex"
	import { ValidationObserver } from "vee-validate"

	export default {
		name: "LoanInstitutionListFilter",
		metaInfo: {
			title: "Institution List - Filter",
		},
		components: { KtvButton, KtvModal, KtvSelect, ValidationObserver },
		props: {
			filter: {
				default: () => {},
				required: true,
				type: Object,
			},
			show: {
				default: false,
				required: true,
				type: Boolean,
			},
		},
		emits: ["close", "on-filter"],
		data() {
			return {
				establishedYearOptions: [],
				is: {
					loading: false,
					showingModal: false,
				},
				legalStatusOptions: [],
				localFilter: {
					establishedYear: null,
					legalStatus: null,
				},
			}
		},
		computed: {
			...mapState("OTHERS", ["legalStatusList"]),
		},
		watch: {
			filter: {
				deep: true,
				handler(filter) {
					this.localFilter = {
						...this.localFilter,
						...filter,
					}
				},
				immediate: true,
			},
			legalStatusList: {
				deep: true,
				handler() {
					this.getLegalStatusOptions()
				},
				immediate: true,
			},
			show: {
				handler(show) {
					this.is = {
						...this.is,
						showingModal: show,
					}
				},
			},
		},
		created() {
			this.getEstablishedYearOptions()
			this.getLegalStatusList()
		},
		methods: {
			...mapActions({
				getLegalStatusList: "OTHERS/getLegalStatusList",
			}),
			close() {
				this.is = {
					...this.is,
					loading: false,
				}

				this.$emit("close")
			},
			getEstablishedYearOptions() {
				const yearOptions = range(1900, new Date().getFullYear() + 1)

				this.establishedYearOptions = map(yearOptions, (estYear) => {
					return {
						text: estYear,
						value: estYear,
					}
				})

				this.establishedYearOptions = this.establishedYearOptions.reverse()
			},
			getLegalStatusOptions() {
				this.legalStatusOptions = map(this.legalStatusList, (legalStatus) => {
					return {
						text: legalStatus.value,
						value: legalStatus.value,
					}
				})
			},
			reset() {
				this.localFilter = {
					establishedYear: null,
					legalStatus: null,
				}

				this.$emit("on-filter", this.localFilter)
				this.close()
			},
			save() {
				const self = this
				this.is = {
					...this.is,
					loading: true,
				}

				this.$refs.form_filter_institution.validate().then((valid) => {
					if (valid) {
						self.$emit("on-filter", this.localFilter)

						self.close()
					} else {
						self.is = {
							...self.is,
							loading: false,
						}
					}
				})
			},
		},
	}
</script>
